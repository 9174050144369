<!-- Login.vue -->

<template>
    <div class="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-cream">
        <div>
            <h2 class="color-primary fw-bold">StoryPro Ai</h2>
        </div>

        <div class="w-full sm:max-w-md mt-6 px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg">
            <h4 class="mb-5 color-primary">Login</h4>
            <form @submit.prevent="submit">
                <div>
                    <label class="block font-medium text-sm text-gray-700" for="email">
                        Email
                    </label>
                    <input v-model="form.email" id="email" type="email" class="mt-1 block w-full" required autofocus autocomplete="username">
                </div>

                <div class="mt-4">
                    <label class="block font-medium text-sm text-gray-700" for="password">
                        Password
                    </label>
                    <input v-model="form.password" id="password" type="password" class="mt-1 block w-full" required autocomplete="current-password">
                </div>

                <div class="block mt-4">
                    <label class="flex items-center">
                        <input type="checkbox" v-model="form.remember" class="form-checkbox">
                        <span class="ml-2 text-sm text-gray-600">Remember me</span>
                    </label>
                </div>

                <div class="flex items-center justify-between mt-4">
                    <a v-if="canResetPassword" href="/forgot-password" class="underline text-sm text-gray-600 hover:text-gray-900">
                        Forgot your password?
                    </a>

                    <button class="ml-4 btn btn-primary" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                        Log in
                    </button>
                </div>

                <!-- Hinzufügen des Registrieren-Links unter dem Formular -->
                <div class="mt-4 text-center">
                    <span class="text-sm text-gray-600">Don’t have an account?</span>
                    <a :href="route('register')" class="ml-1 underline text-sm text-primary hover:text-red-600">Register now
                    </a>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import { useForm } from '@inertiajs/vue3';
import axios from '@/utils/axiosConfig'; // Stellen Sie sicher, dass axios korrekt importiert wird

const props = defineProps({
    canResetPassword: Boolean,
    status: String,
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const submit = () => {
    form.post(route('login'), {
        preserveState: true,
        preserveScroll: true,
    });
};
</script>

<style scoped>
.bg-cream {
    background-color: #F0ECDC;
}

.btn-primary {
    background-color: var(--color-red);
    color: white;
    font-family: 'Anton', sans-serif;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    transition: background-color 0.3s ease;
}

.btn-primary:hover {
    background-color: #B64023;
}

input[type="email"],
input[type="password"] {
    border: 1px solid #d8d8d8;
    background-color: white;
    color: var(--color-black);
    padding: 0.5rem;
    border-radius: 0.25rem;
}

input[type="email"]:focus,
input[type="password"]:focus {
    border: 1px solid #D4B769 !important;
}

input:focus {
    --tw-ring-color: none; /* Neue Farbe für den Fokuszustand */
}

.form-checkbox {
    border: 1px solid #d8d8d8;
}

.form-checkbox:focus {
    --tw-ring-color: none; /* Ändere die Farbe hier auf die gewünschte */
}

.form-checkbox:checked {
    background-color: #D64F32; /* Farbe für das Häkchen */
    border-color: #D64F32;
}

input[type="email"]:focus,
input[type="password"]:focus {
    outline: none;
    border-color: var(--color-red);
}

.form-checkbox {
    color: var(--color-red);
    cursor: pointer;
}

a {
    color: #D64F32 !important;
    transition: color 0.3s ease;
}

a:hover {
    color: #d37f6c !important;
}
</style>
