// src/utils/axiosConfig.js
import axios from 'axios';

// Stellen Sie sicher, dass Axios Cookies sendet
axios.defaults.withCredentials = true;

// Automatisches Einfügen des CSRF-Tokens aus dem Cookie
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Interceptor zum Abfangen von 419-Fehlern
axios.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config;
      if (error.response && error.response.status === 419 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const newToken = await refreshCsrfToken();
          axios.defaults.headers.common['X-CSRF-TOKEN'] = newToken;
          originalRequest.headers['X-CSRF-TOKEN'] = newToken;
          return axios(originalRequest);
        } catch (tokenError) {
          console.error('Error refreshing CSRF token:', tokenError);
          // Optional: Redirect zur Login-Seite
          window.location.href = '/login';
          return Promise.reject(tokenError);
        }
      }
      return Promise.reject(error);
    }
  );

// Funktion zum Lesen eines bestimmten Cookies
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

export default axios;
